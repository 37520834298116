@media (min-width: 1200px) {.main-image {
    width: 100%;
    height: 100%;
}

.buttons-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .action-button {
    position: absolute;
    width: 20%;
    height: 7.5%;
    border-radius: 15px;
    font-size: 30px;
    font-family: 'Oswald', sans-serif;;
    border: none;
    transition: opacity 3.5s ease;
    background-color: antiquewhite;
    text-align: center;
  }
  
  .action-button:hover {
    background-color: rgba(0, 123, 255, 1);
    cursor: pointer
  }
}
@media (max-width: 1199px) {
.main-image {
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1.5s ease-in-out;
}

.buttons-container {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.action-button {
  opacity: 0;
  position: absolute;
  width: 20%;
  height: 7.5%;
  border-radius: 15px;
  font-size: 30px;
  font-family: 'Oswald', sans-serif;;
  border: none;
  transition: opacity 3.5s ease;
  background-color: antiquewhite;
  text-align: center;
}

.action-button:hover {
  opacity: 0;
  background-color: rgba(0, 123, 255, 1);
  cursor: pointer
}
}