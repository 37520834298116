body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  transition: opacity 1.5s ease-in-out;
}

@media (min-width: 1200px) {
  .fade-out {
    opacity: 0;
  }

  .fade-in {
    opacity: 1;
  }
  #text-for-mobiles {
    display: none;
    font-family: 'Oswald', sans-serif;
    font-size: 45px;
    width: 100%;
    text-align: center;
}
}

@media (max-width: 1199px) {
  .fade-out {
    opacity: 0;
  }

  .fade-in {
    opacity: 0;
  }
  #text-for-mobiles {
    font-family: 'Oswald', sans-serif;
    font-size: 45px;
    width: 100%;
    text-align: center;
}
}